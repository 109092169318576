import React from 'react';
import Navbar from './pages/navbar/Navbar';
import Footer from './pages/footer/footer';

const Layout = ({
    children,
  }: {
    children: React.ReactNode
  }) => {
  return (
    <html lang='es'>
        <head>
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-9M2NDZ5D8M"></script>
          <script
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-9M2NDZ5D8M');
              `
            }}
          />
        </head>
        <body className='font-serif bg-gray-200'>
            <Navbar/>
            {children}
            <Footer/>
        </body>
    </html>
  );
};

export default Layout;
