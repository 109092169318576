
import React, { useState } from 'react';
import { Pagination } from '../../../Pagination';
import MostrarCards from '../../../MostrarCards';
import EncabezadoPagina from '../../../encabezadoPagina/EncabezadoPagina';


import Aneroide from "../../../../images/Diagnostico/Aneroide.jpg";
import Bajalenguas_de_Madera from "../../../../images/Diagnostico/Bajalenguas de Madera2.jpg"
import Bajalenguas_de_Madera2 from "../../../../images/Diagnostico/Bajalenguas de Madera.jpg"
import Balanza_Digital_de_baño from "../../../../images/Diagnostico/Balanza Digital de banio.jpg"
import Balanza_de_baño from "../../../../images/Diagnostico/Balanza de banio.jpg"
import Balanza_de_baño2 from "../../../../images/Diagnostico/Balanza de banio2.jpg"
import Balanza from "../../../../images/Diagnostico/Balanza.jpg"
import Balanzas_Alter from "../../../../images/Diagnostico/Balanzas Alter.jpg"
import Blood_Pressure from "../../../../images/Diagnostico/Blood Pressure.jpg"
import Caja_Médica from "../../../../images/Diagnostico/Caja Medica.jpg"
import Cuerpo_Humano from "../../../../images/Diagnostico/Cuerpo Humano.jpg"
import Electrocardiografo from "../../../../images/Diagnostico/Electrocardiografo.jpg"
import Electronic_fat_scale from "../../../../images/Diagnostico/Electronic fat scale.jpg"
import Esfigmo_de_Pared from "../../../../images/Diagnostico/Esfigmo de Pared.jpg"
import Esfigmo_de_Pedestal from "../../../../images/Diagnostico/Esfigmo de Pedestal.jpg"
import Esfigmomanómetro2 from "../../../../images/Diagnostico/Esfigmomanometro2.jpg"
import Esfigmomanómetros from "../../../../images/Diagnostico/Esfigmomanometros.jpg"
import Esqueleto_de_Cuerpo from "../../../../images/Diagnostico/Esqueleto de Cuerpo.jpg"
import Esqueleto_de_Cuerpo2 from "../../../../images/Diagnostico/Esqueleto de Cuerpo2.jpg"
import Estetoscopio from "../../../../images/Diagnostico/Estetoscopio.jpg"
import Estetoscopio2 from "../../../../images/Diagnostico/Estetoscopio2.jpg"
import Estetoscopios_Dobles from "../../../../images/Diagnostico/Estetoscopios Dobles.jpg"
import Fases_de_Crecimiento from "../../../../images/Diagnostico/Fases de Crecimiento.jpg"
import Gel_para_Ultrasonido from "../../../../images/Diagnostico/Gel para Ultrasonido.jpg"
import Guantes_De_Uso from "../../../../images/Diagnostico/Guantes De Uso.jpg"
import Kit_Monitor_de_Glucosa from "../../../../images/Diagnostico/Kit Monitor de Glucosa.jpg"
import Maniquí_de_Partes_del from "../../../../images/Diagnostico/Maniqui de Partes del.jpg"
import Ojo_Gigante from "../../../../images/Diagnostico/Ojo Gigante.jpg"
import Otoscopio from "../../../../images/Diagnostico/Otoscopio.jpg"
import Set_de_diagnóstico from "../../../../images/Diagnostico/Set de diagnostico.jpg"
import Termómetro_Oral_Digital from "../../../../images/Diagnostico/Termometro Oral Digital.jpg"
import Termómetro_Oral from "../../../../images/Diagnostico/Termometro Oral.jpg"
import Termómetro_Rectal from "../../../../images/Diagnostico/Termometro Rectal.jpg"


const Informacion = [
    {Imagen: Aneroide, Descripcion: "Imagen de un aneroide", Titulo: "Aneroide", priority: true},
    {Imagen: Bajalenguas_de_Madera2, Descripcion: "Imagen de bajalenguas de madera", Titulo: "Bajalenguas de Madera", priority: false, subdescripcion: "Bolsa de 100 Unidades"},
    {Imagen: Bajalenguas_de_Madera, Descripcion: "Imagen de una Ceramica", Titulo: "Bajalenguas de Madera, Depresor de Madera", priority: false, subdescripcion: "Adulto y Pediátrico. Estéril y no estéril"},
    {Imagen: Balanza_Digital_de_baño, Descripcion: "Imagen de una balanza digital de baño", Titulo: "Balanza Digital de Baño", priority: false},
    {Imagen: Balanza_de_baño, Descripcion: "Imagen de una balanza de baño", Titulo: "Balanza de Baño", priority: false},
    {Imagen: Balanza_de_baño2, Descripcion: "Imagen de una balanza de baño", Titulo: "Balanza de Baño", priority: false},
    {Imagen: Balanza, Descripcion: "Imagen de una Balanza con altímetro y Cartabón", Titulo: "Balanza con Altímetro y Cartabón", priority: false},
    {Imagen: Balanzas_Alter, Descripcion: "Imagen de una Tabla", Titulo: "Balanzas Alter", priority: false},
    {Imagen: Blood_Pressure, Descripcion: "Imagen de un monitor de presión arterial", Titulo: "Monitor de Presión Arterial", priority: false},
    {Imagen: Caja_Médica, Descripcion: "Imagen de una caja médica", Titulo: "Caja Médica", priority: false},
    {Imagen: Cuerpo_Humano, Descripcion: "Imagen de un un modelo anatomico de un cuerpo humano 85cms", Titulo: "Modelo anatómico", priority: true, subdescripcion : "Cuerpo Humano 85cms"},
    {Imagen: Electrocardiografo, Descripcion: "Imagen de un electrocardiografo", Titulo: "Electrocardiografo", priority: false},
    {Imagen: Electronic_fat_scale, Descripcion: "Imagen de varias básculas de grasa electrónica", Titulo: "Básculas de grasa electrónica", priority: false},
    {Imagen: Esfigmo_de_Pared, Descripcion: "Imagen de un esfigmo de pared", Titulo: "Esfigmo de Pared", priority: false},
    {Imagen: Esfigmo_de_Pedestal, Descripcion: "Imagen de un esfigmo de pedestal", Titulo: "Esfigmo de Pedestal", priority: false},
    {Imagen: Esfigmomanómetro2, Descripcion: "Imagen de un esfigmomanómetro de mercurio/mesa", Titulo: "Esfigmomanómetro de Mercurio/Mesa", priority: false},
    {Imagen: Esfigmomanómetros, Descripcion: "Imagen de un esfigmomanómetros aneroide con estuche", Titulo: "Esfigmomanómetros Aneroide con Estuche", priority: false},
    {Imagen: Esqueleto_de_Cuerpo, Descripcion: "Imagen de un esqueleto de cuerpo humano 85cms", Titulo: "Esqueleto de Cuerpo Humano 85cms", priority: false},
    {Imagen: Esqueleto_de_Cuerpo2, Descripcion: "Imagen de un esqueleto de cuerpo humano 42cms", Titulo: "Esqueleto de Cuerpo Humano 42cms", priority: false},
    {Imagen: Estetoscopio, Descripcion: "Imagen de un estetoscopio", Titulo: "Estetoscopio", priority: false},
    {Imagen: Estetoscopio2, Descripcion: "Imagen de un estetoscopio cardiología III", Titulo: "Estetoscopio Cardiología III", priority: false},
    {Imagen: Estetoscopios_Dobles, Descripcion: "Imagen de estetoscopios dobles para estudiantes", Titulo: "Estetoscopios Dobles para Estudiantes", priority: false},
    {Imagen: Fases_de_Crecimiento, Descripcion: "Imagen de fases de crecimiento de un bebé", Titulo: "Fases de Crecimiento de un Bebé", priority: false},
    {Imagen: Gel_para_Ultrasonido, Descripcion: "Imagen de gel para ultrasonido", Titulo: "Gel para Ultrasonido", priority: false},
    {Imagen: Guantes_De_Uso, Descripcion: "Imagen de guantes de uso de alimentos y hogar", Titulo: "Guantes De Uso De Alimentos Y Hogar", priority: false},
    {Imagen: Kit_Monitor_de_Glucosa, Descripcion: "Imagen de kit monitor de glucosa en sangre", Titulo: "Kit Monitor de Glucosa en Sangre", priority: false},
    {Imagen: Maniquí_de_Partes_del, Descripcion: "Imagen de un maniquí de partes del cuerpo humano", Titulo: "Maniquí de Partes del Cuerpo Humano", priority: false, subdescripcion: "15 Piezas"},
    {Imagen: Ojo_Gigante, Descripcion: "Imagen de un ojo gigante", Titulo: "Ojo Gigante", priority: false},
    {Imagen: Otoscopio, Descripcion: "Imagen de un otoscopio", Titulo: "Otoscopio", priority: false},
    {Imagen: Set_de_diagnóstico, Descripcion: "Imagen de un set de diagnóstico universal otorrinolaringoscopio", Titulo: "Set de diagnóstico Universal Otorrinolaringoscopio", priority: false},
    {Imagen: Termómetro_Oral_Digital, Descripcion: "Imagen de un termómetro oral digital", Titulo: "Termómetro Oral Digital", priority: false},
    {Imagen: Termómetro_Oral, Descripcion: "Imagen de un termómetro oral clínico", Titulo: "Termómetro Oral Clínico", priority: false},
    {Imagen: Termómetro_Rectal, Descripcion: "Imagen de un termómetro rectal clínico", Titulo: "Termómetro Rectal Clínico", priority: false},
  ]

  const NombrePagina = "Oficina / Exámen y Diagnóstico"
  const DescripcionPagina = "Articulos para realizar exámenes médicos y diagnosticos a la venta"

export default function Diagnostico() {
  const [pagina, setPagina] = useState(1);
  const [maxPagina, setMaxPagina] = useState(3);

  function handleClickLeft(){
    if (pagina - 1 >= 1) 
      setPagina(pagina - 1);
      window.scrollTo(0, 0);
  }

  function handleClickRight(){
    if (pagina + 1 <= maxPagina)
      setPagina(pagina + 1); 
      window.scrollTo(0, 0);
  }

  function filterArrayByGroupIndex(inputArray: any[], groupSize: number, groupIndex: number) {
    const startIndex = groupIndex * groupSize;
    const endIndex = startIndex + groupSize;
    
    if (startIndex < inputArray.length) {
        const filteredArray = inputArray.slice(startIndex, endIndex);
        return filteredArray;
    } else {
        return [];
    }
  }

    const result = filterArrayByGroupIndex(Informacion, 12, pagina-1);
    return <div className="container">
      <EncabezadoPagina titulo={NombrePagina} descripcion={DescripcionPagina} />
      <MostrarCards Arreglo={result}></MostrarCards>
      <section>
        <Pagination pagina={pagina} maxPagina={maxPagina} onClickLeft={handleClickLeft} onClickRight={handleClickRight} />
      </section>
    </div>
  }