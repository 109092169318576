import Escritorio from "../../images/Inicio/Escritorio-IL.jpg"
import HogarIL from "../../images/Inicio/Hogar-IL.jpg"
import PromomcionalIL from "../../images/Inicio/Promocionales-IL.jpg"
import InicioMobile from "../../images/Inicio/Inicio-mobile.jpeg"
import CuadroDialogo from "../cuadroDialogo/CuadroDialogo"
import Marcas from "../marcas/Marcas"

const Informacion = [
  {key: 1,titulo: "Desde 1996", descripcion: "estamos en el mercado con productos para la oficina y el hogar en toda Guatemala y América.", 
  svg: "M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"},
  {key: 2, titulo: "Contamos con representaciones", descripcion: "de diversas marcas, para distribuir en Norte, Centro y Sur América.", 
  svg: "m6.115 5.19.319 1.913A6 6 0 0 0 8.11 10.36L9.75 12l-.387.775c-.217.433-.132.956.21 1.298l1.348 1.348c.21.21.329.497.329.795v1.089c0 .426.24.815.622 1.006l.153.076c.433.217.956.132 1.298-.21l.723-.723a8.7 8.7 0 0 0 2.288-4.042 1.087 1.087 0 0 0-.358-1.099l-1.33-1.108c-.251-.21-.582-.299-.905-.245l-1.17.195a1.125 1.125 0 0 1-.98-.314l-.295-.295a1.125 1.125 0 0 1 0-1.591l.13-.132a1.125 1.125 0 0 1 1.3-.21l.603.302a.809.809 0 0 0 1.086-1.086L14.25 7.5l1.256-.837a4.5 4.5 0 0 0 1.528-1.732l.146-.292M6.115 5.19A9 9 0 1 0 17.18 4.64M6.115 5.19A8.965 8.965 0 0 1 12 3c1.929 0 3.716.607 5.18 1.64"},
  {key: 3,titulo: "Venta únicamente a mayoristas", descripcion: "fortalecemos su negocio o emprendimiento, ofreciendo los mejores productos, según sus necesidades y especificaciones.", 
  svg: "M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"},
]

export default function Home() {
  return (
    <>
    <title>IL Proveeduria</title>
  <div className="container" >
    <section className="md:hidden mb-6">
      <img src={InicioMobile} alt="Promocionales"/>
    </section>
    <section className="mx-6 lg:mx-10 xl:mx-0 hidden md:block columns-3 my-6">
      <img className="rounded" src={Escritorio} alt="Escriotrio"/>
      <img className="rounded" src={PromomcionalIL} alt="Promocionales"/>
      <img className="rounded" src={HogarIL} alt="Hogar"/>
    </section>
    <section className="mx-5 lg:mx-10 xl:mx-0">
      <h2 className="pb-3 text-2xl lg:text-3xl"><b>Acerca de nosotros</b></h2>
      {/* Seccion de porque comprar con nosotros */}
      <div className="md:columns-3 md:h-80 lg:h-72 xl:h-52 mb-6">
        {Informacion.map((info) => (
          <CuadroDialogo titulo={info.titulo} descripcion={info.descripcion} svg={info.svg} key={info.key}/>
        ))}
      </div>
    </section>
    <section className="mx-5 lg:mx-10 xl:mx-0 pb-6">
      <h3 className="text-xl font-bold mb-3">Marcas</h3>
      <Marcas/>
    </section>
  </div>
  </>
  )
}
