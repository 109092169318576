export default function NotFound() {
  return (
    <>
    <title>Pagina no encontrada | IL Proveeduria</title>
    <div className='container'>
      <h2 className="text-lg font-bold  mt-6 ">Pagina no encontrada</h2>
      <p>Lo sentimos, no encontramos la página que usted solicito.</p>
      <a href="/" className="mt-6 block text-gray-500 mb-6 hover:text-sky-700 hover:underline ">
      Regresar a la página principal
                                  </a>
    </div>
    </>
  )
}