'use client'

import { Fragment, useEffect, useState  } from "react";
import emailjs from '@emailjs/browser';
import { Dialog, Transition } from '@headlessui/react'
import EncabezadoPagina from "../encabezadoPagina/EncabezadoPagina";

const NombrePagina = "Contactanos"
const DescripcionPagina = "Página de contacto"

const SERVICE_ID = process.env.REACT_APP_SERVICE_ID
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID
const KEY_ID = process.env.REACT_APP_KEY_ID

export default function Contactanos(){
    let [isOpen, setIsOpen] = useState(false)
    let [isOpenError, setIsOpenError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => emailjs.init(`${KEY_ID}`), []);

    const sendEmail = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        setIsLoading(true) // Set loading to true when the request starts

        
        emailjs
          .sendForm(`${SERVICE_ID}`, `${TEMPLATE_ID}`, '#form-contacto', {
            publicKey: `${KEY_ID}`,
          })
          .then(
            () => {
                setIsOpen(true);
                setIsLoading(false) // Set loading to false when the request completes
            },
            (error) => {
                setIsOpenError(true)
                setIsLoading(false) // Set loading to false when the request completes
                console.log(error)
            },
          );
          
      };

      function closeModal() {
        setIsOpen(false)
      }

      function closeModalError() {
        setIsOpenError(false)
      }
    return <div className="container pb-5">
        <EncabezadoPagina titulo={NombrePagina} descripcion={DescripcionPagina} />
        <p className="mt-6 mb-3 pl-6 xl:pl-0">Nos pondrémos en contacto contigo a la brevedad posible.</p>
        <form action="#" id="form-contacto" className="p-6 rounded bg-white border-solid border-2 border-gray-300" onSubmit={sendEmail}>
            <div className="columns-1 md:columns-2">
                <div className=""> 
                    <label htmlFor="nombres">Nombre(s)</label>
                    <input 
                        name="nombres" 
                        id="nombres"
                        type="text"
                        className="mt-2.5 shadow-inner bg-gray-50 w-full block rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6 required:border-red-500" 
                        required
                    />
                </div>
                <div className="mt-5">
                    <label htmlFor="apellidos">Apellido(s)</label>
                    <input 
                        name="apellidos" 
                        id="apellidos"
                        type="text"
                        className="mt-2.5 shadow-inner bg-gray-50 w-full block rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6 required:border-red-500" 
                        required
                    />
                </div>
            </div>
            <div className="columns-1 md:columns-2 mt-5">
                <div className=""> 
                    <label htmlFor="telefono">Telefono</label>
                    <input 
                        name="telefono" 
                        id="telefono" 
                        type="tel" 
                        className="mt-2.5 shadow-inner bg-gray-50 w-full block rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
                        required
                    />
                </div>
                <div className="mt-5">
                    <label htmlFor="correo">Correo</label>
                    <input 
                        name="correo" 
                        id="correo" 
                        type="email" 
                        className="mt-2.5 shadow-inner bg-gray-50 w-full block rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6" 
                        required
                    />
                </div>
            </div>
            <div className="mt-5">
                <div className=""> 
                    <label htmlFor="telefono">Mensaje</label>
                    <textarea
                        name="message"
                        id="message"
                        className="mt-2.5 bg-gray-50 min-h-32 block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-inner ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
                        required
                    />
                </div>
            </div>
            <div className="mt-10">
          <button
            type="submit"
            className="block w-full rounded-md px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            style={{backgroundColor: '#003459'}}
            disabled={isLoading}
          >
            {isLoading ? 'Enviando mensaje' : 'Enviar mensaje'}
          </button>
        </div>
        </form>
        <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                  >
                    <div className="flex justify-center mb-3">
                      <div className="rounded-full bg-green-400 p-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                        </svg>
                      </div>
                    </div>
                    Correo enviado exitosamente
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500 text-center">
                      Hemos recibido tu correo y nos pondremos lo más pronto en contacto contigo.
                    </p>
                  </div>

                  <div className="flex justify-center mt-4">
                    <button
                      type="button"
                      className="flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Entendido
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={isOpenError} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModalError}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                  >
                    <div className="flex justify-center mb-3">
                      <div className="rounded-full bg-red-400 p-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                      </div>
                    </div>
                    Ha ocurrido un error
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500 text-center">
                      No hemos logrado enviar el correo, intentalo más tarde
                    </p>
                  </div>

                  <div className="flex justify-center mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModalError}
                      
                    >
                      Entendido
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

        </div>
        
}
