
import Atril from "../../../../images/Encamamiento/Atril.jpg"
import BandejaDeRiñon from "../../../../images/Encamamiento/BandejaDeRiñon.jpg"
import CacerolaCama from "../../../../images/Encamamiento/CacerolaCama.jpg"
import CamasYCamillas from "../../../../images/Encamamiento/CamasYCamillas.jpg"
import Camillas from "../../../../images/Encamamiento/Camillas.jpg"
import Canceles from "../../../../images/Encamamiento/Canceles.jpg"
import CubetaAceroInoxidable from "../../../../images/Encamamiento/CubetaAceroInoxidable.jpg"
import EncabezadoPagina from "../../../encabezadoPagina/EncabezadoPagina"
import MostrarCards from "../../../MostrarCards"

const NombrePagina = "Oficina / Encamamiento"
const DescripcionPagina = "Articulos de encamamiento para clinicas disponibles para su venta"

const Informacion = [
    {Imagen: BandejaDeRiñon, Descripcion: "Imagen de una bandeja del riñón/emesis", Titulo: "Bandeja del Riñón/Emesis", priority: false, subdescripcion: "Código: SS-71-204 al 209"},
    {Imagen: CacerolaCama, Descripcion: "Imagen de una Posillo", Titulo: "Cacerola para Cama", priority: false, subdescripcion: "Código:SS-71-101"},
    {Imagen: CubetaAceroInoxidable, Descripcion: "Imagen de una cubeta de acero inoxidable", Titulo: "Cubeta de Acero Inoxidable", priority: false, subdescripcion: "Código: SS-71-105 al 107"},
    {Imagen: Atril, Descripcion: "Imagen de un artril portasueros", Titulo: "Atril portasueros", priority: true},
    {Imagen: CamasYCamillas, Descripcion: "Imagen de camas y camillas", Titulo: "Camas y Camillas", priority: false},
    {Imagen: Camillas, Descripcion: "Imagen de dos camillas", Titulo: "Camillas", priority: false},
    {Imagen: Canceles, Descripcion: "Imagen de un cancel", Titulo: "Canceles", priority: false},
  ]

export default function Encamamiento() {
    return <div className="container">
      <EncabezadoPagina titulo={NombrePagina} descripcion={DescripcionPagina} />
      <MostrarCards Arreglo={Informacion}></MostrarCards>
    </div>
  }


