
interface MyEncabezadoProps{
    titulo: string,
    descripcion: string
}

export default function EncabezadoPagina({titulo, descripcion} : MyEncabezadoProps){
    return <div>
        <title>{`${ titulo.includes("/") ? titulo.substring(titulo.lastIndexOf("/") + 1).trim() : titulo} | IL Proveeduria`}</title>
        <meta name="description" content={descripcion} />
        <h3 className="text-gray-500 text-xs md:text-sm mt-6 pl-5 xl:pl-0"><a href="/" className="hover:text-sky-400">Inicio</a> / {titulo}</h3>
        <h3 className="text-2xl mt-3 mb-6 pl-5 xl:pl-0">{ titulo.includes("/") ? titulo.substring(titulo.lastIndexOf("/") + 1).trim() : titulo}</h3>
    </div>
}