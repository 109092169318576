
import Card from "./Card";

interface MyMostrarCardsProps{
    Arreglo: Card[]
}

interface Card{
    Imagen: any,
    Descripcion: string,
    Titulo: string,
    priority: boolean,
    key?: number,
    subdescripcion?: string
}

export default function MostrarCards({Arreglo} : MyMostrarCardsProps){
    return <section className="px-5 xl:px-0 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 mb-5 justify-between">
    {Arreglo.map((card, index) => (
        <Card ubicacion={card.Imagen} descripcion={card.Descripcion} titulo={card.Titulo} priority={card.priority} key={index} subdescripcion={card.subdescripcion}></Card>
    )
    )}
  </section>
}