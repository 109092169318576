interface MyListProps{
    listado: item[]
}

interface item {
  nombre:string
}

export default function ListadoTexto({listado} : MyListProps){
    return <div className="mb-6 ">
      <p className="ml-5 md:ml-0 mb-3">A continuación encontraras alguno de nuestros productos para oficina:</p>
      <ol className="p-6 pl-10 list-outside list-disc columns-2 md:columns-3 bg-white border-solid border-2 border-gray-400 ">
      {listado.map((item, index) =>
        <li key={index}>{item.nombre}</li>
      )}
      </ol>
  </div>
}