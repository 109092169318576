import EncabezadoPagina from "../../encabezadoPagina/EncabezadoPagina"
import ListadoTexto from "../../listadoTexto/ListadoTexto"

const ListadoOficina = [
    {nombre: "Acetatos o transparencias"},
    {nombre: "Acuarelas"},
    {nombre: "Almohadillas para pizarrón"},
    {nombre: "Almohadillas para sellos de hule"},
    {nombre: "Archivadores"},
    {nombre: "Bases"},
    {nombre: "Baterias RAYOVAC"},
    {nombre: "Block Autoadhesivo"},
    {nombre: "Block de Papel Bond"},
    {nombre: "Block de papel periódico"},
    {nombre: "Block Tabular"},
    {nombre: "Bolígrafos Económicos"},
    {nombre: "Borradores"},
    {nombre: "Botes de Basura"},
    {nombre: "Cajas Plásticas y chicas"},
    {nombre: "Calculadoras"},
    {nombre: "Cartapacios"},
    {nombre: "Cartulinas"},
    {nombre: "Cassette"},
    {nombre: "CDR y CDRW MAXEL"},
    {nombre: "Cinta Adhesiva"},
    {nombre: "Clips"},
    {nombre: "Compás"},
    {nombre: "Correctores Líquidos"},
    {nombre: "Corresponsales"},
    {nombre: "Crayones de Cera" },
    {nombre: "Crayones de Madera"},
    {nombre: "Cuadernos"},
    {nombre: "Diskettes MAXEL" },
    {nombre: "Engrapadoras"},
    {nombre: "Estuches geométricos"},
    {nombre: "Etiquetas"},
    {nombre: "Fasterners"},
    {nombre: "Folders"},
    {nombre: "Gafete plástico"},
    {nombre: "Goma escolar" },
    {nombre: "Grapas"},
    {nombre: "Hojas de Líneas, Cuadros y Marginadas"},
    {nombre: "Hules color ámbar"},
    {nombre: "Lana en bolas" },
    {nombre: "Lapices"},
    {nombre: "Libretas de Taquigrafía"},
    {nombre: "Marcadores para pizarrón"},
    {nombre: "Memoria Dimm DS 128MB, DDR bus 333 Kingston"},
    {nombre: "Memoria Dimm DS 256MB, DDR bus 333 Kingston"},
    {nombre: "Papel bond"},
    {nombre: "Papel carbón"},
    {nombre: "Papel celofan"},
    {nombre: "Papel continuo"},
    {nombre: "Papel de china"},
    {nombre: "Papel fluorescent"},
    {nombre: "Papel Higiénico"},
    {nombre: "Papel Kraft"},
    {nombre: "Papel krepe"},
    {nombre: "Papel lustre"},
    {nombre: "Papel para Fotocopiadora"},
    {nombre: "Papel periódico"},
    {nombre: "Papel Sensibilizado"},
    {nombre: "Papeleras"},
    {nombre: "Perforador LEITZ"},
    {nombre: "Pizarrones de corcho"},
    {nombre: "Plasticina"},
    {nombre: "Portatarjetas" },
    {nombre: "Refuerzos LEITZ" },
    {nombre: "Reglas plasticas" },
    {nombre: "Rollos de papel térmico" },
    {nombre: "Rollos Kraft"},
    {nombre: "RUBAFIX"},
    {nombre: "Sacagrapas"},
    {nombre: "Sacapuntas de plastico"},
    {nombre: "Sillas"},
    {nombre: "Sobres" },
    {nombre: "Tablas shanon"},
    {nombre: "Tableros p/ dibujo"},
    {nombre: "Tachuelas Push pin"},
    {nombre: "Tarjetas o fichas de cartulina index base 100"},
    {nombre: "Tarjeteros o ficheros"},
    {nombre: "Temperas"},
    {nombre: "TERMOS"},
    {nombre: "Tijeras"}
]

const NombrePagina = "Oficina / Articulos de Oficina"
const DescripcionPagina = "Articulos de oficina disponibles para su venta"
export default function Oficina(){
    return <div className="container">
        <EncabezadoPagina titulo={NombrePagina} descripcion={DescripcionPagina} />
        <ListadoTexto listado={ListadoOficina} />
    </div>
}