import { useState } from 'react';
import ArticulosOficina from "../../../images/Libreria/Articulos_Oficina.jpg"
import ArticulosOficina2 from "../../../images/Libreria/Articulos_Oficina2.jpg"
import CajaCrayones from "../../../images/Libreria/Caja_Crayones.jpg"
import Calculadoras from "../../../images/Libreria/Calculadoras.jpg"
import Crayones from "../../../images/Libreria/Cayones.jpg"
import Cuadernos from "../../../images/Libreria/Cuadernos.jpg"
import Cuchillas from "../../../images/Libreria/Cuchillas.jpg"
import Dispensador from "../../../images/Libreria/Dispensador_Tape.jpg"
import Engrapadoras from "../../../images/Libreria/Engrapadoras.jpg"
import Lapiceros from "../../../images/Libreria/Lapiceros.jpg"
import Lapices from "../../../images/Libreria/Lapices.jpg"
import Lapices2 from "../../../images/Libreria/Lapices2.jpg"
import Manualidades from "../../../images/Libreria/Manualidades.jpg"
import Marcadores from "../../../images/Libreria/Marcadores.jpg"
import Pizarron_Corcho from "../../../images/Libreria/Pizarron_Corcho.jpg"
import Pizarron_Formica from "../../../images/Libreria/Pizarron_Formica.jpg"
import Pizarron_Otros from "../../../images/Libreria/Pizarron_Otros.jpg"
import Rotafolios from "../../../images/Libreria/Rotafolios.jpg"
import Sacabocados from "../../../images/Libreria/Sacabocados.jpg"
import Strech_Film from "../../../images/Libreria/Strech_Film.jpg"
import Tape from "../../../images/Libreria/Tape.jpg"
import { Pagination } from '../../Pagination';
import MostrarCards from '../../MostrarCards';
import EncabezadoPagina from '../../encabezadoPagina/EncabezadoPagina';

const NombrePagina = "Oficina / Librería"
const DescripcionPagina = "Distintos articulos para librería a la venta"

const Informacion = [
    {Imagen: ArticulosOficina, Descripcion: "Imagen de diversos articulos para oficina", Titulo: "Articulos Oficina", priority: true},
    {Imagen: ArticulosOficina2, Descripcion: "Imagen de diversos articulos para oficina", Titulo: "Articulos Oficina", priority: true},
    {Imagen: CajaCrayones, Descripcion: "Imagen de una caja de crayones", Titulo: "Caja de Crayones", priority: false},
    {Imagen: Calculadoras, Descripcion: "Imagen de varias calculadoras", Titulo: "Calculadoras", priority: false},
    {Imagen: Crayones, Descripcion: "Imagen de varios cayones", Titulo: "Crayones", priority: false},
    {Imagen: Cuadernos, Descripcion: "Imagen de unos cuadernos", Titulo: "Cuadernos", priority: false},
    {Imagen: Cuchillas, Descripcion: "Imagen de unas cuchillas", Titulo: "Cuchillas", priority: false},
    {Imagen: Dispensador, Descripcion: "Imagen de disponsedores de tape", Titulo: "Dispensadores para Tape", priority: false},
    {Imagen: Engrapadoras, Descripcion: "Imagen de engrapadoras de varios colores", Titulo: "Engrapadoras",  priority: false},
    {Imagen: Lapiceros, Descripcion: "Imagen de lapiceros de varios colores", Titulo: "Lapiceros", priority: false},
    {Imagen: Lapices, Descripcion: "Imagen de una caja de lapices", Titulo: "Lapices", priority: false},
    {Imagen: Lapices2, Descripcion: "Imagen de Lapices, Borradores, Sacapuntas y Goma en barra", Titulo: "Lapices, Borradores, Sacapuntas y Goma en barra", priority: false},
    {Imagen: Manualidades, Descripcion: "Imagen de varios objetos para manualidades", Titulo: "Objetos para Manualidades", priority: false},
    {Imagen: Marcadores, Descripcion: "Imagen de marcadores de varios colores", Titulo: "Marcadores", priority: false},
    {Imagen: Pizarron_Corcho, Descripcion: "Imagen de una pizarron de corcho", Titulo: "Pizarron Corcho", priority: false},
    {Imagen: Pizarron_Formica, Descripcion: "Imagen de un pizarron de formica", Titulo: "Pizarron Formica", priority: false},
    {Imagen: Pizarron_Otros, Descripcion: "Imagen de pizarrones de varios colores", Titulo: "Otros Pizarrones", priority: false},
    {Imagen: Rotafolios, Descripcion: "Imagen de rotafolios", Titulo: "Rotafolios", priority: false},
    {Imagen: Sacabocados, Descripcion: "Imagen de varios sacabocados", Titulo: "Sacabocados", priority: false},
    {Imagen: Strech_Film, Descripcion: "Imagen de rollos de plástico", Titulo: "Rollo de Plástico", priority: false}, //Stretch Film
    {Imagen: Tape, Descripcion: "Imagen de varios tape", Titulo: "Tape", priority: false}
  ]

export default function Libreria() {
  const [pagina, setPagina] = useState(1);
  const [maxPagina] = useState(2);
  
  function handleClickLeft(){
    if (pagina - 1 >= 1) 
      setPagina(pagina - 1);
      window.scrollTo(0, 0);
  }

  function handleClickRight(){
    if (pagina + 1 <= maxPagina)
      setPagina(pagina + 1);
      window.scrollTo(0, 0);
    
  }

  function filterArrayByGroupIndex(inputArray: any[], groupSize: number, groupIndex: number) {
    const startIndex = groupIndex * groupSize;
    const endIndex = startIndex + groupSize;
    
    if (startIndex < inputArray.length) {
        const filteredArray = inputArray.slice(startIndex, endIndex);
        return filteredArray;
    } else {
        return [];
    }
  }

    const result = filterArrayByGroupIndex(Informacion, 12, pagina-1);
    return <div className="container">
      <EncabezadoPagina titulo={NombrePagina} descripcion={DescripcionPagina} />
      <MostrarCards Arreglo={result}></MostrarCards>
      <section>
      <Pagination pagina={pagina} maxPagina={maxPagina} onClickLeft={handleClickLeft} onClickRight={handleClickRight} />
      </section>
    </div>
  }


