
import Archivos from "../../../images/Mobiliario y equipo/Archivos.jpg"
import Carro_Curaciones from "../../../images/Mobiliario y equipo/Carro_Curaciones.jpg"
import Escritorio from "../../../images/Mobiliario y equipo/Escritorio.jpg"
import Escritorios from "../../../images/Mobiliario y equipo/Escritorios.jpg"
import Gabinete_Medicina from "../../../images/Mobiliario y equipo/Gabinete_Medicina.jpg"
import Mesa_Individual from "../../../images/Mobiliario y equipo/Mesa_Individual.jpg"
import Sillas from "../../../images/Mobiliario y equipo/Sillas.jpg"
import Variedad_Immobiliario from "../../../images/Mobiliario y equipo/Variedad_Immobiliario.jpg"
import EncabezadoPagina from "../../encabezadoPagina/EncabezadoPagina"
import MostrarCards from "../../MostrarCards"
import { useState } from "react"

const NombrePagina = "Oficina / Mobiliario y equipo"
const DescripcionPagina = "Mobiliario para equipar oficina a la venta"

const Informacion = [
    {Imagen: Archivos, Descripcion: "Imagen de varios archivos", Titulo: "Variedad de Archivos", priority: true},
    {Imagen: Carro_Curaciones, Descripcion: "Imagen de un carro para curaciones", Titulo: "Carro para Curaciones", priority: false},
    {Imagen: Escritorio, Descripcion: "Imagen de un escritorio", Titulo: "Escritorio", priority: false},
    {Imagen: Escritorios, Descripcion: "Imagen de mesas, escritorios y archivos", Titulo: "Mesas, Escritorios y Archivos", priority: false},
    {Imagen: Gabinete_Medicina, Descripcion: "Imagen de un gabinete para medicina", Titulo: "Gabinete de Medicina", priority: false},
    {Imagen: Mesa_Individual, Descripcion: "Imagen de una mesa", Titulo: "Mesa", priority: false},
    {Imagen: Sillas, Descripcion: "Imagen de bancos, sillas y baterias", Titulo: "Bancos, Sillas y Baterias", priority: false},
    {Imagen: Variedad_Immobiliario, Descripcion: "Imagen de variedad de mobiliario", Titulo: "Variedad de Mobiliario", priority: false},
  ]

export default function Mobiliario() {
  const [pagina] = useState(1);


  function filterArrayByGroupIndex(inputArray: any[], groupSize: number, groupIndex: number) {
    const startIndex = groupIndex * groupSize;
    const endIndex = startIndex + groupSize;
    
    if (startIndex < inputArray.length) {
        const filteredArray = inputArray.slice(startIndex, endIndex);
        return filteredArray;
    } else {
        return [];
    }
  }

    const result = filterArrayByGroupIndex(Informacion, 12, pagina-1);
    return <div className="container">
      <title>Mobiliario | IL Proveeduria</title>
      <meta name="description" content="Productos de mobiliario y equipo medico a la venta" />
      <EncabezadoPagina titulo={NombrePagina} descripcion={DescripcionPagina} />
      <MostrarCards Arreglo={result}></MostrarCards>
    </div>
  }


