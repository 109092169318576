import Bote_105_Litros from "../../images/Limpieza/Bote_105_Litros.jpg"
import Bote_25_Litros from "../../images/Limpieza/Bote_25_Litros.jpg"
import Caja_Jumbo from "../../images/Limpieza/Caja_Jumbo.jpg"
import Caja_Jumbo_Transparente from "../../images/Limpieza/Caja_Jumbo_Transparente.jpg"
import Escoba from "../../images/Limpieza/Escoba.jpg"
import Escoba2 from "../../images/Limpieza/Escoba2.jpg"
import Palangana from "../../images/Limpieza/Palangana.jpg"
import EncabezadoPagina from "../encabezadoPagina/EncabezadoPagina"
import MostrarCards from "../MostrarCards"
import { useState } from "react"


const NombrePagina = "Limpieza"
const DescripcionPagina = "Articulos para limpieza de oficina y hogar disponibles a la venta"

const Informacion = [
    {Imagen: Bote_25_Litros, Descripcion: "Imagen de un bote de 25 litros", Titulo: "Bote de 25 Litros", priority: true},
    {Imagen: Bote_105_Litros, Descripcion: "Imagen de un bote de 105 litros", Titulo: "Bote de 105 Litros", priority: false},
    {Imagen: Caja_Jumbo, Descripcion: "Imagen de una caja jumbo", Titulo: "Caja Jumbo", priority: false},
    {Imagen: Caja_Jumbo_Transparente, Descripcion: "Imagen de una caja jumbo transparente", Titulo: "Caja Jumbo Transparente", priority: false},
    {Imagen: Escoba, Descripcion: "Imagen de escobas", Titulo: "Escobas", priority: false},
    {Imagen: Escoba2, Descripcion: "Imagen de escobas", Titulo: "Escobas", priority: false},
    {Imagen: Palangana, Descripcion: "Imagen de una palangana", Titulo: "Palangana", priority: false}
  ]

export default function Limpieza() {
  const [pagina] = useState(1);

  function filterArrayByGroupIndex(inputArray: any[], groupSize: number, groupIndex: number) {
    const startIndex = groupIndex * groupSize;
    const endIndex = startIndex + groupSize;
    
    if (startIndex < inputArray.length) {
        const filteredArray = inputArray.slice(startIndex, endIndex);
        return filteredArray;
    } else {
        return [];
    }
  }

    const result = filterArrayByGroupIndex(Informacion, 12, pagina-1);
    return <div className="container">
      <EncabezadoPagina titulo={NombrePagina} descripcion={DescripcionPagina} />
      <MostrarCards Arreglo={result}></MostrarCards>
    </div>
  }


