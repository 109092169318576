import Baco from '../../images/Baco.jpg'
import Bic from '../../images/Bic.jpg'
import Kawaki from '../../images/Kawaki.jpg'
import Kimberly from '../../images/Kimberly_Clarck.jpg'
import Multibox from '../../images/Multibox.jpg'
import Kartell from '../../images/Kartell.jpg'
import Rayovac from '../../images/Rayovac.jpg'
import Tesa from '../../images/Tesa.jpg'
import Tayuyo from '../../images/Tayuyo.jpg'
import Thermos from '../../images/Thermos.jpg'

const MarcasImagenes = [
  {Imagen: Baco, alt: "Imagen Baco", key:2},
  {Imagen: Bic, alt: "Imagen Bic", key:3},
  {Imagen: Multibox, alt: "Imagen Multibox", key:4},
  {Imagen: Tesa, alt: "Imagen Tesa", key:6},
  {Imagen: Kawaki, alt: "Imagen Tesa", key:8},
  {Imagen: Kimberly, alt: "Imagen Tesa", key:9},
  {Imagen: Kartell, alt: "Imagen Tesa", key:11},
  {Imagen: Rayovac, alt: "Imagen Tesa", key:15},
  {Imagen: Tayuyo, alt: "Imagen Tesa", key:17},
  {Imagen: Thermos, alt: "Imagen Tesa", key:18}
]
export default function Marcas(){
    
    return <div className="bg-white border-solid border-2 border-gray-400 break-after-column rounded-lg p-6">
    <div className="columns-2 md:columns-5 space-y-10">
    
    {MarcasImagenes.map((info) => (
      <img src={info.Imagen} alt={info.alt} key={info.key}/>   
      )
    )}
    </div>
</div>
}