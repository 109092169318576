interface MyCardProps{
    ubicacion: any;
    descripcion: string;
    titulo: string;
    priority: boolean,
    subdescripcion?: string
}

export default function Card({ubicacion, descripcion, titulo, priority, subdescripcion} : MyCardProps){
    return <div className="rounded flex-col inline-flex scale-up-center bg-white border-solid border-2 border-gray-400 shadow-md p-6">
    <img className="rounded size-48 md:size-52 lg:size-56 place-self-center" src={ubicacion} alt={descripcion}/>
    <p className="text-center mt-6 text-lg leading-none text-black">{titulo}</p>    
    {subdescripcion != null ? <p className="mt-2 text-center text-base leading-none text-slate-500">{subdescripcion}</p> : ""}
  </div>
}

