import { useState } from 'react';
import { Pagination } from '../Pagination';
import MostrarCards from '../MostrarCards';
import EncabezadoPagina from '../encabezadoPagina/EncabezadoPagina';

import Agenda_Diaria from "../../images/Promocionales/Agenda Diaria.jpg";
import Agendas from "../../images/Promocionales/Agendas.jpg";
import Bolsa_y_Ataché from "../../images/Promocionales/Bolsa y Atache.jpg";
import Camisas_Tipo_Polo from "../../images/Promocionales/Camisas Tipo Polo.jpg";
import Cuaderno from "../../images/Promocionales/Cuaderno.jpg";
import Maletín from "../../images/Promocionales/Maletín.jpg";
import Maletín2 from "../../images/Promocionales/Maletín2.jpg";
import Marcadores from "../../images/Promocionales/Marcadores.jpg";
import Mochila from "../../images/Promocionales/Mochila.jpg";
import Mochila2 from "../../images/Promocionales/Mochilla2.jpg";
import Mochila3 from "../../images/Promocionales/Mochilla3.jpg";
import Mochillas from "../../images/Promocionales/Mochillas.jpg";
import Promocionales1 from "../../images/Promocionales/Promocionales1.jpg";
import Promocionales2 from "../../images/Promocionales/Promocionales2.jpg";
import Promocionales3 from "../../images/Promocionales/Promocionales3.jpg";
import Promocionales4 from "../../images/Promocionales/Promocionales4.jpg";
import Promocionales5 from "../../images/Promocionales/Promocionales5.jpg";
import Reglas from "../../images/Promocionales/Reglas.jpg";
import Tape from "../../images/Promocionales/Tape.jpg";
import Tazas from "../../images/Promocionales/Tazas.jpg";
import Utiles_de_Oficina from "../../images/Promocionales/Utiles de Oficina.jpg";
import Vasos from "../../images/Promocionales/Vasos.jpg";

const Informacion = [
    {Imagen: Agenda_Diaria, Descripcion: "Imagen de una agenda diaria", Titulo: "Agenda Diaria", priority: true},
    {Imagen: Agendas, Descripcion: "Imagen de varias agendas", Titulo: "Agendas", priority: false},
    {Imagen: Bolsa_y_Ataché, Descripcion: "Imagen de una bolsa y ataché", Titulo: "Bolsa y Ataché", priority: false},
    {Imagen: Camisas_Tipo_Polo, Descripcion: "Imagen de varias camisas tipo polo", Titulo: "Camisas Tipo Polo", priority: false},
    {Imagen: Cuaderno, Descripcion: "Imagen de un cuaderno", Titulo: "Cuaderno", priority: false},
    {Imagen: Maletín, Descripcion: "Imagen de un maletín", Titulo: "Maletín", priority: false},
    {Imagen: Maletín2, Descripcion: "Imagen de un maletín", Titulo: "Maletín", priority: false},
    {Imagen: Marcadores, Descripcion: "Imagen de varios marcadores", Titulo: "Marcadores", priority: false},
    {Imagen: Mochila, Descripcion: "Imagen de una mochila", Titulo: "Mochila", priority: false},
    {Imagen: Mochila2, Descripcion: "Imagen de una mochila", Titulo: "Mochila", priority: false},
    {Imagen: Mochila3, Descripcion: "Imagen de una mochila", Titulo: "Mochila", priority: true},
    {Imagen: Mochillas, Descripcion: "Imagen de una mochila", Titulo: "Mochila", priority: false},
    {Imagen: Promocionales1, Descripcion: "Imagen de productos pomocionales", Titulo: "Productos Promocionales", priority: false},
    {Imagen: Promocionales2, Descripcion: "Imagen de productos promocionales medicos", Titulo: "Productos Promocionales Medicos", priority: false},
    {Imagen: Promocionales3, Descripcion: "Imagen de productos promocionales área farmaceutica", Titulo: "Productos Promocionales Área Farmaceutica", priority: false},
    {Imagen: Promocionales4, Descripcion: "Imagen de productos promocionales USB", Titulo: "Productos Promocionales USB", priority: false},
    {Imagen: Promocionales5, Descripcion: "Imagen de productos promocionales vajillas", Titulo: "Productos Promocionales Vajillas", priority: false},
    {Imagen: Reglas, Descripcion: "Imagen de unas reglas", Titulo: "Reglas", priority: false},
    {Imagen: Tape, Descripcion: "Imagen de un tape", Titulo: "Tape", priority: false},
    {Imagen: Tazas, Descripcion: "Imagen de unas tazas", Titulo: "Tazas", priority: false},
    {Imagen: Utiles_de_Oficina, Descripcion: "Imagen de utiles de oficina", Titulo: "Utiles de Oficina", priority: false},
    {Imagen: Vasos, Descripcion: "Imagen de vasos", Titulo: "Vasos", priority: false},
   
  ]

  const NombrePagina = "Promocionales"
  const DescripcionPagina = "Articulos promocionales disponibles a la venta"
export default function Promocionales() {
  const [pagina, setPagina] = useState(1);
  const [maxPagina, setMaxPagina] = useState(2);

  function handleClickLeft(){
    if (pagina - 1 >= 1) 
      setPagina(pagina - 1);
      window.scrollTo(0, 0);
  }

  function handleClickRight(){
    if (pagina + 1 <= maxPagina)
      setPagina(pagina + 1);
      window.scrollTo(0, 0);
  }

  function filterArrayByGroupIndex(inputArray: any[], groupSize: number, groupIndex: number) {
    const startIndex = groupIndex * groupSize;
    const endIndex = startIndex + groupSize;
    
    if (startIndex < inputArray.length) {
        const filteredArray = inputArray.slice(startIndex, endIndex);
        return filteredArray;
    } else {
        return [];
    }
  }

    const result = filterArrayByGroupIndex(Informacion, 12, pagina-1);
    return <div className="container">
      <EncabezadoPagina titulo={NombrePagina} descripcion={DescripcionPagina} />
      <MostrarCards Arreglo={result}></MostrarCards>
      <section>
        <Pagination pagina={pagina} maxPagina={maxPagina} onClickLeft={handleClickLeft} onClickRight={handleClickRight} />
      </section>
    </div>
  }