interface DialogoProps{
    titulo: string;
    descripcion: string;
    svg: string
}

export default function CuadroDialogo({descripcion, titulo, svg} : DialogoProps){
    return(
        <div className="rounded-md p-6 mb-6 bg-white border-solid border-2 border-gray-400  h-full">
        <div className="flex justify-center">
            <div className="rounded-full bg-blue-200 p-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d={svg} />    
                </svg>
            </div>
            
        </div>
        <p className="text-lg md:text-base lg:text-lg font-bold mt-2 mb-3 text-center">
            {titulo}
            </p>
        <p className="text-center">{descripcion}</p>
    </div>
    ) 
}
