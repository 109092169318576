import { BrowserRouter, Routes, Route  } from "react-router-dom";

import './App.css';
import Layout from "./layout";
import Home from "./pages/home/Home";
import Oficina from "./pages/Oficina/oficina/Oficina";
import Libreria from "./pages/Oficina/oficina/Libreria";
import Mobiliario from "./pages/Oficina/oficina/Mobiliario";
import Encamamiento from "./pages/Oficina/oficina/equipoMedico/Encamamiento";
import Diagnostico from "./pages/Oficina/oficina/equipoMedico/Diagnostico";
import Ortopedia from "./pages/Oficina/oficina/equipoMedico/Ortopedia";
import Hogar from "./pages/hogar/Hogar";
import Limpieza from "./pages/limpieza/Limpieza";
import Promocionales from "./pages/promocionales/Promocionales";
import Contactanos from "./pages/contactenos/Contactenos";
import NotFound from "./pages/not-found/NotFound";

function App() {
  return (
    <Layout>
      <BrowserRouter>
        <Routes>
          <Route path="/" Component={Home} />
          <Route path="/Oficina" Component={Oficina} />
          <Route path="/Libreria" Component={Libreria} />
          <Route path="/Mobiliario" Component={Mobiliario} />
          <Route path="/Encamamiento" Component={Encamamiento} />
          <Route path="/Diagnostico" Component={Diagnostico} />
          <Route path="/Ortopedia" Component={Ortopedia} />
          <Route path="/Hogar" Component={Hogar} />
          <Route path="/Limpieza" Component={Limpieza} />
          <Route path="/Promocionales" Component={Promocionales} />
          <Route path="/Contactanos" Component={Contactanos} />
          <Route path='*'  Component={NotFound} />
        </Routes>
      </BrowserRouter>
    </Layout>
  );
}

export default App;
