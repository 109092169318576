
import { useState } from 'react';
import Caja from "../../images/Hogar/Caja.jpg"
import Vajilla from "../../images/Hogar/Vajilla.jpg"
import Ceramica from "../../images/Hogar/Ceramica.jpg"
import Posillo from "../../images/Hogar/Posillo.jpg"
import Canasta from "../../images/Hogar/Canasta.jpg"
import Cuchara2 from "../../images/Hogar/Cuchara medidora 2.jpg"
import Silla from "../../images/Hogar/Silla.jpg"
import Tabla from "../../images/Hogar/Tabla para picar.jpg"
import Taza from "../../images/Hogar/Taza medidora.jpg"
import Taza2 from "../../images/Hogar/Taza medidora2.jpg"
import Taza3 from "../../images/Hogar/Taza medidora3.jpg"
import VajillaPlastica from "../../images/Hogar/Vajillas plasticas.jpg"
import Vaso from "../../images/Hogar/Vaso plastico.jpg"
import Cuchara1 from "../../images/Hogar/Cuchara medidora.jpg"
import { Pagination } from '../Pagination';
import MostrarCards from '../MostrarCards';
import EncabezadoPagina from '../encabezadoPagina/EncabezadoPagina';


const Informacion = [
    {Imagen: Caja, Descripcion: "Imagen de una caja", Titulo: "Caja Plástica", priority: true},
    {Imagen: Vajilla, Descripcion: "Imagen de una vajilla", Titulo: "Vajilla", priority: false},
    {Imagen: Ceramica, Descripcion: "Imagen de una ceramica", Titulo: "Cerámica", priority: false},
    {Imagen: Posillo, Descripcion: "Imagen de una posillo", Titulo: "Posillos Cerámicos", priority: false},
    {Imagen: Canasta, Descripcion: "Imagen de una canasta", Titulo: "Canasto Rect.", priority: false},
    {Imagen: Cuchara2, Descripcion: "Imagen de una cuchara", Titulo: "Cucharas Med.", priority: false},
    {Imagen: Silla, Descripcion: "Imagen de una silla", Titulo: "Silla Turín", priority: false},
    {Imagen: Tabla, Descripcion: "Imagen de una tabla", Titulo: "Tabla para Picar", priority: false},
    {Imagen: Taza, Descripcion: "Imagen de una taza", Titulo: "Taza Medidora", priority: false},
    {Imagen: Taza2, Descripcion: "Imagen de una taza", Titulo: "Taza Medidora", priority: false},
    {Imagen: Taza3, Descripcion: "Imagen de una taza", Titulo: "Tazas Medidoras", priority: false},
    {Imagen: VajillaPlastica, Descripcion: "Imagen de una vajilla plastica", Titulo: "Vajillas Plásticas", priority: true},
    {Imagen: Cuchara1, Descripcion: "Imagen de una cuchara", Titulo: "Cuchara Medidora", priority: false},
    {Imagen: Vaso, Descripcion: "Imagen de una vaso", Titulo: "Vaso de 9 Onzas", priority: false}
  ]

  const NombrePagina = "Hogar"
  const DescripcionPagina = "Articulos para el hogar disponibles a la venta"
export default function Hogar() {
  const [pagina, setPagina] = useState(1);
  const [maxPagina, setMaxPagina] = useState(2);

  function handleClickLeft(){
    if (pagina - 1 >= 1) 
      setPagina(pagina - 1);
      window.scrollTo(0, 0);
  }

  function handleClickRight(){
    if (pagina + 1 <= maxPagina)
      setPagina(pagina + 1);
      window.scrollTo(0, 0);  
    
  }

  function filterArrayByGroupIndex(inputArray: any[], groupSize: number, groupIndex: number) {
    const startIndex = groupIndex * groupSize;
    const endIndex = startIndex + groupSize;
    
    if (startIndex < inputArray.length) {
        const filteredArray = inputArray.slice(startIndex, endIndex);
        return filteredArray;
    } else {
        return [];
    }
  }

    const result = filterArrayByGroupIndex(Informacion, 12, pagina-1);
    return <div className="container">
      <EncabezadoPagina titulo={NombrePagina} descripcion={DescripcionPagina} />
      <MostrarCards Arreglo={result}></MostrarCards>
      <section>
        <Pagination pagina={pagina} maxPagina={maxPagina} onClickLeft={handleClickLeft} onClickRight={handleClickRight} />
      </section>
    </div>
  }
