
import { useState } from 'react';
import { Pagination } from '../../../Pagination';
import MostrarCards from '../../../MostrarCards';
import EncabezadoPagina from '../../../encabezadoPagina/EncabezadoPagina';

import Andador from "../../../../images/Ortopedia/Andador.jpg";
import Andador2 from "../../../../images/Ortopedia/Andador2.jpg";
import Bastones_Ajustables from "../../../../images/Ortopedia/Bastones Ajustables.jpg";
import Bastones from "../../../../images/Ortopedia/Bastones.jpg";
import Corset_Lumbar from "../../../../images/Ortopedia/Corset Lumbar.jpg";
import Inmobililizador_de_Dedo from "../../../../images/Ortopedia/Inmobililizador de Dedo.jpg";
import Inmobilizador_de_Clavicula from "../../../../images/Ortopedia/Inmobilizador de Clavicula.jpg";
import Inmobilizador_de_Cuello from "../../../../images/Ortopedia/Inmobilizador de Cuello.jpg";
import Inmobilizador_de_Muñeca from "../../../../images/Ortopedia/Inmobilizador de Munieca.jpg";
import Inmobilizador_de_Rodilla from "../../../../images/Ortopedia/Inmobilizador de Rodilla.jpg";
import Muletas from "../../../../images/Ortopedia/Muletas.jpg";
import Paper_Face_Mask from "../../../../images/Ortopedia/Paper Face Mask.jpg";
import Productos_de_Fisioterapia from "../../../../images/Ortopedia/Productos de Fisioterapia.jpg";
import Silla_de_Rueda from "../../../../images/Ortopedia/Silla de Rueda.jpg";
import Silla_de_Rueda2 from "../../../../images/Ortopedia/Silla de Rueda2.jpg";
import Silla_de_Rueda3 from "../../../../images/Ortopedia/Silla de Rueda3.jpg";
import Silla_de_Rueda4 from "../../../../images/Ortopedia/Silla de Rueda4.jpg";
import Silla_de_Rueda5 from "../../../../images/Ortopedia/Silla de Rueda5.jpg";
import Variedad_de_Andadores from "../../../../images/Ortopedia/Variedad de Andadores.jpg";
import Variedad_de_Bastones from "../../../../images/Ortopedia/Variedad de Bastones.jpg";
import Variedad_de_Muletas from "../../../../images/Ortopedia/Variedad de Muletas.jpg";
import Variedad_de_Muletas2 from "../../../../images/Ortopedia/Variedad de Muletas2.jpg";
import Venda_Elástica_Spandex from "../../../../images/Ortopedia/Venda Elastica Spandex.jpg";
import Vendas_Elásticas from "../../../../images/Ortopedia/Vendas Elasticas.jpg";
import Venda_elásticas2 from "../../../../images/Ortopedia/Vendas elasticas2.jpg";


const Informacion = [
    {Imagen: Andador, Descripcion: "Imagen de un andador", Titulo: "Andador", priority: true},
    {Imagen: Andador2, Descripcion: "Imagen de un andador", Titulo: "Andador", priority: false},
    {Imagen: Bastones_Ajustables, Descripcion: "Imagen de bastones ajustables", Titulo: "Bastones Ajustables", priority: false, subdescripcion: "de Aluminio, Acero Inoxidable y Plástico"},
    {Imagen: Bastones, Descripcion: "Imagen de bastones", Titulo: "Bastones", priority: false},
    {Imagen: Corset_Lumbar, Descripcion: "Imagen soporte lumbar", Titulo: "Corsé Lumbar", priority: false},
    {Imagen: Inmobililizador_de_Dedo, Descripcion: "Imagen de un inmobililizador de dedo", Titulo: "Inmobililizador de Dedo", priority: false},
    {Imagen: Inmobilizador_de_Clavicula, Descripcion: "Imagen de un inmobilizador de clavicula", Titulo: "Inmobilizador de Clavicula", priority: false},
    {Imagen: Inmobilizador_de_Cuello, Descripcion: "Imagen de un inmobilizador de cuello", Titulo: "Inmobilizador de Cuello", priority: false},
    {Imagen: Inmobilizador_de_Muñeca, Descripcion: "Imagen de un inmobilizador de muñeca", Titulo: "Inmobilizador de Muñeca", priority: false},
    {Imagen: Inmobilizador_de_Rodilla, Descripcion: "Imagen de un inmobilizador de rodilla", Titulo: "Inmobilizador de Rodilla", priority: false},
    {Imagen: Muletas, Descripcion: "Imagen de una muleta", Titulo: "Muleta", priority: false},
    {Imagen: Paper_Face_Mask, Descripcion: "Imagen de una mascarilla de papel", Titulo: "Mascarilla de Papel", priority: false},
    {Imagen: Productos_de_Fisioterapia, Descripcion: "Imagen de productos de fisioterapia", Titulo: "Productos de Fisioterapia", priority: false},
    {Imagen: Silla_de_Rueda, Descripcion: "Imagen de una silla de rueda", Titulo: "Silla de Rueda", priority: false},
    {Imagen: Silla_de_Rueda2, Descripcion: "Imagen de una silla de rueda", Titulo: "Silla de Rueda", priority: false},
    {Imagen: Silla_de_Rueda3, Descripcion: "Imagen de una silla de rueda", Titulo: "Silla de Rueda", priority: false},
    {Imagen: Silla_de_Rueda4, Descripcion: "Imagen de una silla de rueda", Titulo: "Silla de Rueda", priority: false},
    {Imagen: Silla_de_Rueda5, Descripcion: "Imagen de una silla de rueda", Titulo: "Silla de Rueda", priority: false},
    {Imagen: Variedad_de_Andadores, Descripcion: "Imagen de varios andadores", Titulo: "Variedad de Andadores", priority: false},
    {Imagen: Variedad_de_Bastones, Descripcion: "Imagen de varios de bastones", Titulo: "Variedad de Bastones", priority: false},
    {Imagen: Variedad_de_Muletas, Descripcion: "Imagen de varias muletas", Titulo: "Variedad de Muletas", priority: false},
    {Imagen: Variedad_de_Muletas2, Descripcion: "Imagen de varias muletas", Titulo: "Variedad de Muletas", priority: false},
    {Imagen: Venda_Elástica_Spandex, Descripcion: "Imagen de una venda elástica spandex", Titulo: "Venda Elástica Spandex", priority: false},
    {Imagen: Vendas_Elásticas, Descripcion: "Imagen de vendas elásticas", Titulo: "Vendas Elásticas", priority: false},
    {Imagen: Venda_elásticas2, Descripcion: "Imagen de unas vendas elásticas 100% algodón", Titulo: "Vendas elásticas 100% Algodón", priority: false},
    
  ]

  const NombrePagina = "Oficina / Ortopedia"
  const DescripcionPagina = "Equpo de ortopedia a la venta"
export default function Ortopedia() {
  const [pagina, setPagina] = useState(1);
  const [maxPagina, setMaxPagina] = useState(3);
  
  function handleClickLeft(){
    if (pagina - 1 >= 1) 
      setPagina(pagina - 1);
      window.scrollTo(0, 0);
  }

  function handleClickRight(){
    if (pagina + 1 <= maxPagina)
      setPagina(pagina + 1);
      window.scrollTo(0, 0);
  }

  function filterArrayByGroupIndex(inputArray: any[], groupSize: number, groupIndex: number) {
    const startIndex = groupIndex * groupSize;
    const endIndex = startIndex + groupSize;
    
    if (startIndex < inputArray.length) {
        const filteredArray = inputArray.slice(startIndex, endIndex);
        return filteredArray;
    } else {
        return [];
    }
  }

    const result = filterArrayByGroupIndex(Informacion, 12, pagina-1);
    return <div className="container">
      <EncabezadoPagina titulo={NombrePagina} descripcion={DescripcionPagina} />
      <MostrarCards Arreglo={result}></MostrarCards>
      <section>
        <Pagination pagina={pagina} maxPagina={maxPagina} onClickLeft={handleClickLeft} onClickRight={handleClickRight} />
      </section>
    </div>
  }