interface MyPaginationProps{
    pagina: number;
    maxPagina : number;
    onClickLeft: () => void;
    onClickRight: () => void;
}

export function Pagination({pagina, maxPagina, onClickLeft, onClickRight} : MyPaginationProps){
    return <div className="flex justify-center font-serif mb-4">
        <button className="rounded-full bg-sky-500 border-solid border-2 border-sky-500 disabled:opacity-70 mr-5" onClick={onClickLeft} disabled={pagina == 1? true: false}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18" />
        </svg>
        </button>
        Página {pagina} de {maxPagina}
        <button className="rounded-full bg-sky-500 border-solid border-2 border-sky-500 ml-5 disabled:opacity-70" onClick={onClickRight} disabled={pagina == maxPagina ? true : false}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
        </svg>
        </button>
    </div>
}